<template>
  <CSidebar
    aside
    :show="$store.state.latestRestaurantActivitiesAsideShow"
    @update:show="(val) => $store.commit('set', ['latestRestaurantActivitiesAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <div class="d-flex justify-content-between align-items-center" style="height: 56px;">
      <CButton class="text-danger" color="link" variant="ghost"
        @click="showAllActivities()"
      >
        <CIcon name="cil-fingerprint" size="xl" /> Show all activities
      </CButton>

      <CSidebarClose @click.native="$store.commit('toggle', 'latestRestaurantActivitiesAsideShow')" />
    </div>

    <!-- Error Alert -->
    <CListGroup class="list-group-accent" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent" v-show='!showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Latest Activity Logs
      </CListGroupItem>

      <template v-for="activity in logs">
        <CListGroupItem class="list-group-item-divider">
          <div><CIcon name="cil-bolt" class="mr-1" />{{ activity?.event }}</div>
          <div class="small text-muted mt-1">{{ activity?.description }}</div>
          <div class="small text-muted mt-1">
            <CIcon name="cil-calendar" class="mr-2" />
            {{ moment(activity.created_at).calendar() }}
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading"/>

  </CSidebar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "LatestActivities",
  props: ['restaurant'],
  data() {
    return {
      loading: false,
      logs: [],

      errorAlertMessage: '',
      showErrorAlert: false,
    };
  },
  watch: {
    async reloadParams() {
      this.$store.state.latestRestaurantActivitiesAsideShow && await this.getLatestActivities();
    },
  },
  computed: {
    ...mapState(['filters']),
    reloadParams() {
      return [this.$store.state.latestRestaurantActivitiesAsideShow];
    },
  },

  methods: {
    async getLatestActivities() {
      this.logs = [];
      this.loading = true;
      await this.$axios.get(this.$backend.ACTIVITIES.GET_LATEST_ACTIVITIES, {
        params: {
          log_name: 'restaurant',
          causer_type: 'Restaurant',
          causer: this.restaurant.id,
          top: 10
        }
      })
        .then(({data}) => {
          this.logs = data.data;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showAllActivities() {
      this.filters.activities.logName = "restaurant";
      this.filters.activities.causerType = "Restaurant";
      this.filters.activities.selectedCauser = {
        "value": this.restaurant.id,
        "label": `#${this.restaurant.id} ${this.restaurant.restaurant_postcode} ${this.restaurant.restaurant_name}`
      };

      this.$router.push({ name: "Activities" });
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
