<template>
  <CSidebar aside overlaid colorScheme="light" size="lg" :show="$store.state.changeLogoAsideShow"
    @update:show="(val) => $store.commit('set', ['changeLogoAsideShow', val])">
    <CSidebarClose @click.native="$store.commit('toggle', 'changeLogoAsideShow')" />

    <CListGroup class="scrollable list-group-accent mt-5">
      <CListGroupItem class="mb-0 list-group-item-accent-primary bg-light text-center font-weight-bold text-uppercase">
        Change Logo
      </CListGroupItem>

      <CListGroupItem class="list-group-item-accent-secondary">
        <div class="image-container">
          <CImg class="logo" :src="previewImage" placeholderColor="lightgray" />
        </div>

        <div class="d-flex justify-content-between">
          <label for="logo" class="btn btn-link m-0 p-0">
            <CIcon name="cil-file" class="mr-1" />Choose a new one
          </label>
          <CInputFile id="logo" style="display: none" @change="onFileChanged" />

          <CLink class="text-danger" :class="{ 'disable-item': !logo }" @click="removeLogo">
            <CIcon name="cil-trash" class="mr-1" />Remove
          </CLink>
        </div>
      </CListGroupItem>

      <CListGroupItem class="sticky-bottom list-group-item-accent-primary">
        <CButton color="primary" shape="pill" :disabled="newLogo === null || loading" @click="change">Change</CButton>
      </CListGroupItem>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading" />
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChangeLogo",
  props: {
    restaurantId: { default: null },
    slug: { type: [String], default: '' },
    logo: { type: [String], default: '' }
  },
  data() {
    return {
      previewImage: null,
      newLogo: null,

      loading: false,
    };
  },

  watch: {
    async reloadParams() {
      if (this.$store.state.changeLogoAsideShow) {
        this.previewImage = this.logo;
        this.newLogo = null;
      }
    },
  },

  computed: {
    ...mapGetters(["errors"]),

    reloadParams() {
      return [this.$store.state.changeLogoAsideShow];
    }
  },

  methods: {
    checkIfValid(fileName) {
      var filename_arr = fileName.split(".");
      var file_ext = filename_arr[filename_arr.length - 1];

      return ["jpeg", "jpg", "png", "gif"].indexOf(file_ext) !== -1;
    },
    onFileChanged(files, event) {
      if (files.length > 0) {
        const file = files[0];

        if (this.checkIfValid(file.name)) {
          this.previewImage = URL.createObjectURL(file);
          this.newLogo = file;
        }
        else {
          this.previewImage = null;
          this.newLogo = null;
        }
      }
    },
    removeLogo() {
      this.previewImage = null;
      this.newLogo = { name: '' };
    },

    async change() {
      try {
        if (!this.newLogo) {
          return;
        }

        this.loading = true;

        if (this.newLogo.name !== '') {
          await this.uploadLogo();
        }

        const { data } = await this.$axios.put(this.$backend.RESTAURANT.UPDATE_SETTINGS.replace("{id}", this.restaurantId), {
          restaurant_logo: this.newLogo.name
        });

        this.$emit('update', data.restaurant.restaurant_logo_path);
        this.$store.commit('set', ['changeLogoAsideShow', false]);

        if (this.newLogo.name === '') {
          await this.removeLogoFromCDN();
        }
      } catch (error) {
        const errorMessage = typeof this.errors === 'object' ? Object.values(this.errors).join() : this.errors;
        this.$toast.error(errorMessage);
      } finally {
        this.loading = false;
      }
    },

    async uploadLogo() {
      const formData = new FormData();
      formData.append('restaurant_logo', this.newLogo, this.newLogo.name);
      formData.append('restaurant_slug', this.slug);

      const { data } = await this.$axios.post(this.$backend.RESTAURANT.SAVE_LOGO, formData);
      this.newLogo = { name: data.data.file_name };
    },

    async removeLogoFromCDN() {
      await this.$axios.delete(this.$backend.RESTAURANT.REMOVE_LOGO_FROM_CDN.replace("{id}", this.restaurantId))
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}

.image-container {
  margin: 10px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.logo {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}
</style>
