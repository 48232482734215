<template>
<div>
  <CCard v-if="item">
    <CCardHeader>
      <CIcon name="cil-restaurant"/>
      <h5 class="d-inline ml-2">
        {{ item?.restaurant_name }}
      </h5>

      <div class="card-header-actions d-flex flex-gap-1">
        <div class="d-flex align-items-center border-right">
          <CButton variant="ghost" shape="pill" target="_blank" v-if="item.restaurant_slug"
            :href="item.restaurant_slug_path | addUriScheme" v-c-tooltip="{ content: item.restaurant_slug_path }">
            <CIcon name="cil-restaurant" />
          </CButton>

          <CButton variant="ghost" shape="pill" target="_blank" v-if="item.website_etenonline_be"
            :href="item.website_etenonline_be | addUriScheme" v-c-tooltip="{ content: item.website_etenonline_be }">
            <CIcon name="cil-link" />
          </CButton>

          <CButton class="mr-1" variant="ghost" shape="pill" target="_blank" v-if="item.website"
            :href="item.website | addUriScheme" v-c-tooltip="{ content: item.website }">
            <CIcon name="cil-external-link" />
          </CButton>
        </div>

        <div class="d-flex align-items-center flex-gap-1">
          <CButton color="danger" variant="ghost" shape="pill"
            v-c-tooltip="{ content: 'Latest Activities Caused by Restaurant' }"
            @click="showLatestActivities()"
          >
            <CIcon name="cil-fingerprint" size="lg" />
          </CButton>

          <CButton color="primary" variant="ghost" shape="pill"
            v-c-tooltip="{ content: 'Restaurant Messages' }"
            @click="showMessages()"
          >
            <CIcon name="cil-envelope-closed" size="lg" />
          </CButton>
        </div>

        <div class="d-flex align-items-center flex-gap-1 border-left">
          <CButton class="ml-1" color="info" variant="ghost" shape="pill"
            v-c-tooltip="{ content: 'Edit Restaurant' }"
            :to="{ name: 'Edit Restaurant', params: { id: itemId }}"
          >
            <CIcon name="cil-align-left" size="lg" />
          </CButton>

          <CButton color="dark" :to="prevRoute">
            <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
          </CButton>
        </div>
      </div>
    </CCardHeader>

    <CCardBody class="pb-0">

      <div class="d-flex flex-column flex-md-row flex-gap-2 mb-4">
        <div class="flex-grow-1">
          <UnsignedContract
            v-if="item.email && !item.upload_contract"
            :id="item.id"
            :email="item.email"
          />
        </div>
        <div>
          <CAlert v-if="item.hasdeliveryService"  color="success" class="d-flex flex-gap-2 align-items-center mb-0 h-100">
            <CIcon name="cil-truck" size="2xl" />
            <div>
              <strong>Delivery Service enabled.</strong>
              <div v-if="!item.hubs_count" class="d-flex flex-gap-2 align-items-center text-danger font-weight-bold flash-it">
                ⚠ Add the postcode ({{ item.restaurant_postcode }}) to the scope of a hub.
              </div>
            </div>
          </CAlert>
        </div>
      </div>

      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <div
        class="resto-header d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4 p-3 h-100 flex-gap-3"
        :class="{'table-danger': item.none_active, 'table-success': !item.none_active}"
        :style="{ backgroundImage: 'url(' + item.hero_image?.image_url + ')' }"
      >
        <div class="logo">
          <CImg
            :src="getValidImageUrl(item.restaurant_logo_path)"
            class="logo__image"
            :class="{'resto-none-active': item.none_active, 'resto-active': !item.none_active}"
            placeholderColor="lightgray"
          />
          <div class="logo__content" @click="changelogo" role="button">
            <span class="logo__icon"><CIcon name="cil-camera" size="xl"/></span>
            <span class="logo__text">Change Logo</span>
          </div>
        </div>

        <div class="p-2 address-container">
          <address class="blockquote">
            <strong>{{ item.restaurant_name }}</strong><br>
            {{ item.restaurant_address }} <br>
            {{ item.restaurant_city }}, {{ item.countryId }} {{ item.restaurant_postcode }}<br>
            <abbr title="Phone Number"><CIcon name="cil-phone" />: {{ item.restaurant_tel }}</abbr><br>
            <abbr title="Mobile Number"><CIcon name="cil-mobile" />: {{ item.restaurant_gsm }}</abbr>
          </address>
          <address class="mb-0 blockquote">
            <abbr title="Email"><CIcon name="cil-at" />:
            <a :href="`mailto:${item.email}`">{{ item.email }}</a></abbr>
          </address>
          <div class="button ml-n2">
            <CLink :to="{ name: 'Edit Restaurant', params: { id: item.id }, query: { tab: 0 } }">
              <CIcon name="cil-pen" /> Edit Contact Info
            </CLink>
          </div>
        </div>

        <div class="action" @click="selectHeroImage">Change Hero Image</div>
      </div>

      <h5 class="mb-0 mt-5">Restaurant Settings</h5>
      <p>These settings help you quickly change important configs.</p>
      <div class="list-group mb-5 shadow">

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <strong class="mb-2">Not Published</strong>
              <p class="text-muted mb-0">This setting controls whether the restaurant is actively published.</p>
            </div>
            <div class="col-auto">
              <CSwitch
                :checked.sync="item.none_active"
                @update:checked="updateRestaurantStatus"
                labelOn="YES"
                labelOff="NO"
                color="danger"
                shape="pill"
              />
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <strong class="mb-2">Today Closed</strong>
              <p class="text-muted mb-0">Quickly change if the place is open or closed.</p>
            </div>
            <div class="col-auto">
              <CSwitch
                :checked.sync="item.rest_closed_today"
                @update:checked="updateSettings({ 'rest_closed_today': item.rest_closed_today })"
                labelOn="YES"
                labelOff="NO"
                color="success"
                shape="pill"
              />
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <strong class="mb-2">Custom Opening Times</strong>
              <p class="text-muted mb-0">Easily set customized opening hours for the restaurant.</p>
            </div>
            <div class="col-auto">
              <CButton
                :to="{ name: 'RestaurantOpeningTimes', params: { restaurant_id: item.id } }"
                size="sm"
                color="info"
                variant="ghost"
                shape="pill"
              >
                <CIcon name="cil-align-left" />
              </CButton>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <strong class="mb-2" :class="{ 'text-success': item.hasdeliveryService }">
                <CIcon name="cil-truck" /> Delivery Service
              </strong>
              <p class="text-muted mb-0">Update Menute Delivery Service status.</p>
            </div>
            <div class="col-auto">
              <CButton
                :to="{ name: 'DeliveryService' }"
                size="sm"
                color="info"
                variant="ghost"
                shape="pill"
              >
                <CIcon name="cil-align-left" />
              </CButton>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <strong class="mb-2 mr-2">Send Restaurant Login Details to Holders</strong>
              <CBadge shape="pill" color="info">NEW</CBadge>
              <p class="text-muted mb-0">Send the restaurant's username and password directly to the restaurant emails.</p>
            </div>
            <div class="col-auto">
              <CButton size="sm" color="info" variant="ghost" shape="pill" @click="sendRestaurantDetails('login-details')">
                <CIcon name="cil-send" />
              </CButton>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row align-items-center">
            <div class="col">
              <strong class="mb-2 mr-2">Send Magicsoftware Link to Holders</strong>
              <CBadge shape="pill" color="info">NEW</CBadge>
              <p class="text-muted mb-0">Send Magicsoftware Link directly to the restaurant emails.</p>
            </div>
            <div class="col-auto">
              <CButton size="sm" color="info" variant="ghost" shape="pill" @click="sendRestaurantDetails('magicsoftware-link')">
                <CIcon name="cil-send" />
              </CButton>
            </div>
          </div>
        </div>
      </div>

      <CRow>
        <!-- Account Balance -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Account Balance"
            :text= String(this.item.account_balance)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-euro" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantPaymentList', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Payments
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Orders -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Orders"
            :text= String(this.item.restaurant_orders_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-cart" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantOrderList', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Orders
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Reviews -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Reviews"
            :text= String(this.item.restaurant_reviews_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-star" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantReviews', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Reviews
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Zip Codes -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Zip Codes"
            :text= String(this.item.zip_codes_linked_restaurants_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-location-pin" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantLinkedZIPCodes', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Zip Codes
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Linked Types -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Linked Types"
            :text= String(this.item.restaurant_linked_types_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-text" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantLinkedTypes', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Linked Types
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Linked Categories -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Categories"
            :text= String(this.item.categories_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-folder" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantLinkedCategories', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Categories
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Linked Menus -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Menus"
            :text= String(this.item.menus_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-dinner" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantLinkedMenus', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Menus
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Menu Sjabloons -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Menu Sjabloons"
            :text= String(this.item.menu_sjabloons_count)
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-object-ungroup" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'Restaurant Menu Sjabloons', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Menu Sjabloons
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Tasks -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Tasks"
            :text= "`${this.item.open_tasks_count} / ${this.item.tasks_count}`"
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-bolt" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantTaskList', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Tasks
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>


        <!-- Permissions -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Permissions"
            :text="`${this.item.permissions_count}`"
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-lock-unlocked" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'RestaurantPermissionList', params: { restaurant_id: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Permissions
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>

        <!-- Revenues  -->
        <CCol class="widget" col="12" sm="6" xl="3">
          <CWidgetIcon
            header="Revenues"
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-bar-chart" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CLink
                  :to="{ name: 'Revenue', query: { r: item.id } }"
                  class="font-sm text-muted d-flex justify-content-between"
                  target="_self"
                >
                  Show Revenues
                  <CIcon name="cil-arrow-right" width="16"/>
                </CLink>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>

        <!-- Device Link -->
        <CCol class="device-widget widget" col="12" xl="3">
          <CWidgetIcon
            header="Device"
            :text=textLinkedDevice
            color="gradient-dark"
            :icon-padding="false"
          >
            <CIcon name="cil-mobile" class="" width="24" />
            <template #footer>
              <CCardFooter>
                <CButton
                  v-if="linkedDevice"
                  color="danger"
                  size="sm"
                  shape="pill"
                  @click="unlinkDevice"
                >
                  <CIcon name="cil-x" /> Unlink Device
                </CButton>

                <CTabs v-else :active-tab.sync="activeTab">
                  <CTab v-if="previousRestDevice" title="Get From the Previous" class="tab-item">
                    <ul class="pt-2">
                      <li>Previous Restaurant ID: #{{ previousRestDevice.restaurant_id }}</li>
                      <li>Device SID: {{ previousRestDevice.device?.sid }} (#{{ previousRestDevice.device_id }})</li>
                      <li>Software: {{ previousRestDevice.device?.software ? 'YES' : 'NO' }}</li>
                    </ul>

                    <CButton
                      type="submit"
                      color="success"
                      size="sm"
                      shape="pill"
                      @click="linkDevice($event, previousRestDevice)"
                    >
                      <CIcon name="cil-pin" /> Link Device
                    </CButton>
                  </CTab>

                  <CTab title="Select New" class="tab-item">
                    <CForm
                      class="pt-2"
                      @submit="linkDevice"
                    >
                      <v-select
                        class="mb-2"
                        v-model="selectedDevice"
                        :options="availableDevices"
                        placeholder="Please choose a device.."
                        :searchable="true"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!selectedDevice"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>

                      <CButton
                        type="submit"
                        color="success"
                        size="sm"
                        shape="pill"
                      >
                        <CIcon name="cil-pin" /> Link Device
                      </CButton>
                    </CForm>
                  </CTab>
                </CTabs>
              </CCardFooter>
            </template>
          </CWidgetIcon>
        </CCol>
      </CRow>
    </CCardBody>

    <CElementCover :opacity="0.4" v-show="loading"/>

    <ChangeHeroImage :imageId="item.hero_image?.id" @update="changedHeroImage" />
    <ChangeLogo :restaurantId="this.item.id" :slug="this.item.restaurant_slug" :logo="getValidImageUrl(this.item.restaurant_logo_path)" @update="changedlogo" />
    <SendRestaurantDetails :restaurantId="item.id" :emails="uniqueEmails" :detail="detailToSend" />
    <LatestActivities :restaurant="{ id: item.id, restaurant_name: item.restaurant_name, restaurant_postcode: item.restaurant_postcode }" />
  </CCard>

  <div v-else>
    <CSpinner v-if="loading" color="primary" />
    <h6 v-else>Restaurant not found!</h6>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangeHeroImage from "@/components/Restaurant/ChangeHeroImage";
import ChangeLogo from "@/components/Restaurant/ChangeLogo";
import SendRestaurantDetails from "@/components/Restaurant/SendRestaurantDetails";
import LatestActivities from "./LatestActivities";

export default {
  name: 'ViewRestaurant',
  components: {
    UnsignedContract: () => import('@/views/restaurants/sign-contract/UnsignedContract.vue'),
    ChangeHeroImage,
    ChangeLogo,
    SendRestaurantDetails,
    LatestActivities
  },

  data() {
    return {
      loading: false,
      item: null,
      linkedDevice: null,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      uniqueEmails: [],
      availableDevices: [],
      selectedDevice: null,
      activeTab: 0,
      detailToSend: null,

      // Get previous page
      prevRoute: null,
    };
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  computed: {
    ...mapGetters(["errors"]),

    itemId() { return this.$route.params.id },
    textLinkedDevice() { return this.linkedDevice ? this.linkedDevice : "No device linked yet!" },
    previousRestDevice() {
      const { previous } = this.item;
      const linkWithDevice = previous?.restaurant_device_link_with_device;

      if (linkWithDevice && !linkWithDevice.device.software) {
        return linkWithDevice;
      }

      return null;
    }
  },

  async mounted() {
    this.getAvailableDevices();
    await this.getValues();
  },

  methods: {
    async getValues() {
      this.loading = true;
      await this.$axios.get(this.$backend.RESTAURANT.SHOW.replace("{id}", this.itemId))
        .then((response) => {
          this.item = response.data;
          this.linkedDevice = response.data.restaurant_device_link_with_device?.device?.sid;
        })
        .catch((error) => {
          this.errorAlertMessage = { 'Error': error.response.data.message }
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getValidImageUrl(url) {
      const ORIGIN_CDN = process.env.VUE_APP_ORIGIN_CDN;

      return url
        ? this.$globalFunc.isURL(url)
          ? url
          : ORIGIN_CDN + url
        : "";
    },

    async getAvailableDevices() {
      const response = await this.$axios.get(this.$backend.DEVICE.AVAILABLE_DEVICES);
      this.availableDevices = Object.keys(response.data).map(key => response.data[key].sid);
    },

    linkDevice(e, previousRestDevice = null) {
      e.preventDefault();
      this.loading = true;
      this.$axios
        .post(this.$backend.RESTAURANT.LINK_DEVICE, {
          restaurant_id: this.itemId,
          device_sid: previousRestDevice
            ? previousRestDevice.device?.sid
            : this.selectedDevice,
          previous_restaurant_id: previousRestDevice?.restaurant_id,
        })
        .then((response) => {
          this.selectedDevice = response.data.data.device_sid;

          this.availableDevices = this.availableDevices.filter(
            (item) => item !== this.selectedDevice
          );
          this.linkedDevice = this.selectedDevice;
        })
        .finally(() => {
          this.loading = false;
          this.getAvailableDevices(); // authored by Administrator
        });
    },

    unlinkDevice() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to unlink the device?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios.post(this.$backend.RESTAURANT.UNLINK_DEVICE, { 'restaurant_id': this.itemId, 'device_sid': this.linkedDevice })
              .then(() => {
                this.availableDevices.push(this.linkedDevice);
                this.availableDevices.sort();

                this.selectedDevice = this.linkedDevice;
                this.linkedDevice = null;
              })
              .catch((error) => {
                this.errorAlertMessage = { 'Error': error.response.data.message }
                this.showErrorAlert = true
              })
              .finally(() => {
                this.loading = false;
                this.getAvailableDevices(); // authored by Administrator
              });
            // ---------------
          }
        },
      });
    },

    updateRestaurantStatus(none_active) {
      let isPublished = !none_active;

      if (isPublished && this.item.published_previous_count > 0) {
        this.$confirm({
          title: "Confirm",
          message: `⚠️ The restaurant has previous restaurant. Do you want to unpublish this?`,
          button: { no: "No", yes: "Yes" },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: this.updateActive,
        });
      } else {
        this.updateActive();
      }
    },
    updateActive(unpublishPrevious = false) {
      this.loading = true;

      this.$axios
        .post(this.$backend.RESTAURANT.PUBLISH.replace("{id}", this.item.id), {
          'none-active': this.item.none_active,
          'unpublish-previous': unpublishPrevious,
        })
        .catch((error) => {
          this.item.none_active = !this.item.none_active;
          this.$toast.error(`${error.response.data.message}`);
          this.errorAlertMessage = { 'Error': error.response.data.message }
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async updateSettings(data) {
      this.loading = true;

      try {
        await this.$axios.put(this.$backend.RESTAURANT.UPDATE_SETTINGS.replace("{id}", this.item.id), data);
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      }

      this.loading = false;
    },

    selectHeroImage() {
      this.$store.commit('set', ['changeHeroImageAsideShow', true]);
    },
    async changedHeroImage(image) {
      try {
        const { data } = await this.$axios.post(this.$backend.RESTAURANT.ASSIGN_HERO_IMAGE, {
          "image_id": image.id,
          "restaurants": this.item.id.toString(),
        });
        this.item.hero_image = image;
        this.$toast.success(`${data.message}`);
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      }
    },

    changelogo() {
      this.$store.commit('set', ['changeLogoAsideShow', true]);
    },
    changedlogo(logo_path) {
      this.item.restaurant_logo_path = logo_path;
    },

    showLatestActivities() {
      this.$store.commit('set', ['latestRestaurantActivitiesAsideShow', true]);
    },
    showMessages() {
      this.$store.commit('restaurants/setSelectedRestaurant', this.item.id)
      this.$router.push({ name: "Message List" });
    },
    sendRestaurantDetails(detail) {
      const parseEmails = (emailsString) =>
        emailsString
          .split(';')
          .map(email => email.trim())
          .filter(email => email); // Filter out empty strings

      const allEmails = [
        parseEmails(this.item.email || ''),
        parseEmails(this.item.eigenaar1_email || ''),
        parseEmails(this.item.eigenaar2_email || ''),
      ].flat();

      this.uniqueEmails = Array.from(new Set(allEmails));
      this.detailToSend = detail;

      this.$store.commit('set', ['sendRestaurantDetailsAsideShow', true]);
    },
  }
};
</script>

<style scoped>
footer:not(.device-widget .card-footer).card-footer {
  height: 44px;
  padding-left: 1rem !important;
}

.device-widget .card-footer {
  padding: 0.5rem 0.25rem !important;
}

.address-container {
  position: relative;
  background-color: #fff;
  opacity: 0.75;
  border-radius: 0.25rem;
}

.button {
  opacity: 0;
  transition: opacity .35s ease;
  background: rgba(0, 0, 0, .3);
  text-align: center;
  position: absolute;
  width: 100%;
  border-radius: 25px;
}

.button a {
  text-align: center;
  color: white;
  z-index: 1;
}

.address-container:hover .button {
  opacity: 1;
}

.resto-active {
  border: 0.375rem solid #64A64A;
}

.resto-none-active {
  border: 0.375rem solid #B32638;
}

/* Change logo */
.logo {
  position: relative;
  border-radius: 50%;
}

.logo:hover .logo__content {
  opacity: 1;
}
.logo:hover {
  overflow: hidden;
  background-color: #111;
}
.logo:hover .logo__image {
  opacity: .5;
}
.logo__image {
  border-radius: 50% !important;
  background-color: #fff !important;
  width: 150px;
  height: 150px;
  object-fit: cover;
  opacity: 1;
  transition: opacity .2s ease-in-out;
}
.logo__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
.logo__icon {
  color: white;
  padding-bottom: 8px;
}
.logo__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}
</style>

<style>
.device-widget .tab-item>a {
  height: 32px !important;
  padding: 5px !important;
}

.device-widget .text-muted {
  font-size: 0.8rem !important;
}

.c-chart-brand {
  position: absolute;
  width: 100%;
  height: 100px;
}

.widget .card,
.widget .card-footer {
  background-color: #f8f8ff;
}

.widget .text-value {
  font-size: 1rem;
}

.widget .text-muted {
  font-size: 1.5rem;
}

.resto-header {
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.resto-header .action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  backface-visibility: hidden;
  opacity: 0;
}

.resto-header:hover .action {
  opacity: 1;
}
</style>
