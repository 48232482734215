<template>
  <CSidebar aside :show="$store.state.sendRestaurantDetailsAsideShow"
    @update:show="(val) => $store.commit('set', ['sendRestaurantDetailsAsideShow', val])" colorScheme="light" overlaid
    size="lg">
    <CSidebarClose @click.native="$store.commit('toggle', 'sendRestaurantDetailsAsideShow')" />

    <CListGroup class="scrollable list-group-accent mt-5">
      <CListGroupItem class="mb-0 list-group-item-accent-primary bg-light text-center font-weight-bold text-uppercase">
        Send {{ detail | normalizeSentence }}
      </CListGroupItem>

      <CListGroupItem class="list-group-item-accent-secondary">
        <h5>Select emails to send:</h5>
        <ul class="checklist">
          <li v-for="(mail, index) in emails">
            <label class="for-checkbox">
              <input type="checkbox" v-model="selectedEmails" :value="mail" />
              {{ mail }}
            </label>
          </li>
        </ul>
      </CListGroupItem>

      <CListGroupItem class="sticky-bottom list-group-item-accent-primary bg-white">
        <CButton color="primary" :disabled="!selectedEmails.length" @click="send">
          <CIcon name="cil-send" /> Send
        </CButton>
      </CListGroupItem>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading" />
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SendRestaurantDetails",
  props: {
    restaurantId: { default: null },
    emails: { type: Array, default: () => [] },
    detail: { type: String, default: null },
  },
  data() {
    return {
      loading: false,
      selectedEmails: [],
    };
  },

  watch: {
    async reloadParams() {
      if (this.$store.state.sendRestaurantDetailsAsideShow) {
        this.selectedEmails = [];
      }
    },
  },

  computed: {
    ...mapGetters(["errors"]),

    reloadParams() {
      return [this.$store.state.sendRestaurantDetailsAsideShow];
    }
  },

  methods: {
    async send() {
      this.loading = true;

      try {
        switch (this.detail) {
          case 'login-details':
            await this.sendDetails(this.$backend.RESTAURANT.SEND_LOGIN_DETAILS);
            this.$toast.success(`The restaurant login details were successfully mailed.`);
            break;
          case 'magicsoftware-link':
            await this.sendDetails(this.$backend.RESTAURANT.SEND_MAGICSOFTWARE_LINK);
            this.$toast.success(`Magicsoftware link was successfully mailed.`);
            break;
          default:
            break;
        }
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      } finally {
        this.loading = false;
        this.$store.commit('set', ['sendRestaurantDetailsAsideShow', false]);
      }
    },

    async sendDetails(url) {
      await this.$axios.post(url.replace("{id}", this.restaurantId), {
        emails: this.selectedEmails.join(',')
      });
    }
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}

ul.checklist {
  list-style: none;
  margin-top: 1.25rem;
  padding-left: 0.5rem;
}

ul.checklist li {
  line-height: 1.75;
  font-size: 1.10em;
}

ul.checklist input {
  width: 1.25rem;
  height: 1.25rem;
}

label.for-checkbox,
label.for-checkbox>input {
  cursor: pointer;
}

label.for-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
